.list-unordered {
  list-style: none;

  &__item {
    position: relative;
    padding-left: 3.5rem;

    &::before {
      content: "";
      position: absolute;
      top: 12px;
      left: 0;
      display: block;
      width: 2rem;
      height: 1px;
      background: currentcolor;

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        top: 16px;
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        top: 19px;
      }
    }

    a {
      color: $color-secondary;
    }

  }
}
