.dual-image {
  margin-bottom: var(--spacer-l);

  .row-01 {
    .col-01 {
      @include grid-column(2, -1, 4, -1, null, null, 5, -1);
    }
  }

  .row-02 {
    .dual-image__picture {
      margin-bottom: var(--spacer-xs);
    }

    .col-01 {
      @include grid-column(1, 4, 1, 7);
    }

    .col-02 {
      @include grid-column(4, -1, 7, -1);
    }
  }
}
