// helper blocks
.job-categories-jobs-list {
  list-style: none;

  &__item:not(:first-child) {
    margin-top: 1rem;
  }

  &__link {
    svg {
      flex: 0 0 auto;
    }
  }
}

// main block
.job-categories {
  margin-top: var(--spacer-s);

  &__link {
    margin-bottom: var(--spacer-xs);
  }
}
