.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacer-xs);

  @media screen and (max-width: map-get($max-width-breakpoints, 'large')) {
    grid-template-columns: 1fr;
  }

  a {
    color: inherit;
  }

  &__field {
    grid-column: span 1;

    display: flex;
    flex-direction: column;
    gap: var(--spacer-xxs);

    &--checkbox {
      display: grid;
      grid-template-columns: min-content auto;

      align-items: center;

      font-size: 20px;
      font-weight: 400;

      .form__field-error {
        grid-column: 1 / -1;
      }

      & + .form__field--checkbox {
        margin-top: -12px;
      }
    }

    &--span-row {
      grid-column: 1 / -1;
    }

    &-error {
      font-size: 14px;
      color: $color-error;

      max-height: 0px;
      overflow: hidden;

      opacity: 1;

      transition: max-height 180ms ease-in-out, opacity 120ms ease-in-out;

      &--hidden {
        opacity: 0;
      }
    }

    &--has-error * {
      --color-border: #{$color-error} !important;
    }
  }

  &__actions {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .btn {
      width: min-content;
      background: $color-black;
      color: $color-white;

      &:hover {
        background: $color-secondary-dark;
        color: $color-white;
      }
    }
  }

  &__privacy {
    grid-column: 1 / -1;
  }

  &__submit-message {
    background: $color-grey-dark-2;
    color: $color-white;
    padding: 18px 24px;

    font-size: 18px;
    line-height: 1.6;

    width: fit-content;
    grid-column: 1 / -1;

    &--hidden {
      display: none;
    }
  }
}

%form-field {
  background: $color-black;

  --color-border: #{$color-secondary-dark};
  --border-thickness: 0px;

  border: none;
  box-shadow: 0px 0px 0px var(--border-thickness) var(--color-border);

  color: $color-white;

  outline: none;

  font-family: $text-font-stack-primary;
  font-size: 20px;
  font-weight: 400;

  border-radius: 9999px;

  padding: calc(var(--spacer-xs) / 2) 36px;

  margin: 1px;

  transition: box-shadow 120ms ease-in-out;

  position: relative;

  &:focus {
    --border-thickness: 2.4px;
  }

  &:-webkit-autofill {
    color: $color-white !important;
    -webkit-text-fill-color: $color-white !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;

    box-shadow: 0px 0px 0px var(--border-thickness) var(--color-border),
      0 0 0 1000px $color-secondary-dark inset !important;

    &:focus {
      --color-border: #{$color-black};
      --border-thickness: 2.4px;
    }
  }
}

.input {
  @extend %form-field;
}

input[type='checkbox'] {
  width: 28px;
  height: 28px;

  appearance: none;
  border: none;
  outline: none;

  border-radius: 5px;

  background: $color-black;
  color: $color-white;

  --color-border: #{$color-black};
  --border-thickness: 0px;

  border: none;
  box-shadow: inset 0px 0px 0px var(--border-thickness) var(--color-border);

  transition: box-shadow 120ms ease-in-out, background 120ms ease-in-out;

  flex-shrink: 0;

  &:checked {
    background: $color-secondary-dark;
    --border-thickness: 6px;
  }
}
