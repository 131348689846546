.text-with-title {
  margin-bottom: var(--spacer-l);

  .row-01 {
    .col-01 {
      @include grid-column(1, -1, 1, 8, null, null, 1, 4);
    }

    .col-02 {
      @include grid-column(2, -1, 4, -1, null, null, 5, -1);
    }
  }
}
