.fade-in {
  animation: fade-in 350ms cubic-bezier(.165, .84, .44, 1) both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
