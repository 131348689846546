// main block
.two-col-gallery {
  .row-03 {
    margin-bottom: var(--spacer-l);
  }

  .row-04 {
    .col-01 {
      @include grid-column(2, 5, 1, 4);

      margin-bottom: var(--spacer-m);

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        margin-bottom: unset;
      }
    }

    .col-02 {
      @include grid-column(1, -1, 4, -1, 4, 11, 5, 11);
    }
  }
}
