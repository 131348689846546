/* stylelint-disable scss/at-extend-no-missing-placeholder */
.portable-text {
  h1 {
    @extend .heading;
    @extend .heading--h1;
  }

  h2 {
    @extend .heading;
    @extend .heading--h2;
  }

  h3 {
    @extend .heading;
    @extend .heading--h3;
  }

  h4 {
    @extend .heading;
    @extend .heading--h4;
  }

  h1,
  h2,
  h3,
  h4 {
    &:not(:first-child) {
      margin-top: var(--spacer-s);
    }
  }

  p {
    @extend .copy;
    @extend .copy--big;

    margin: 0 0 1.2rem;
  }

  a {
    @extend .link;
    @extend .link--secondary;
  }

  span {
    @extend .text-bold;
  }

  ul {
    @extend .list-unordered;

    margin: var(--spacer-s) 0;

    > li {
      @extend .list-unordered__item;
      @extend .copy;
      @extend .copy--big;
    }
  }
}
