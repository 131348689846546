// main block
.news-teaser {
  .row-01 {
    margin-bottom: var(--spacer-l);
  }

  .row-02 {
    margin-bottom: var(--spacer-l);

    &:last-child {
      margin-bottom: 0;
    }

    .col-01 {
      @include grid-column(1, -1, 4, -1, null, null, 5, -1);
    }
  }

  .row-03 {
    grid-template-areas:
      "first first first first first first"
      "last last last last last last"
      "cta cta cta cta cta cta";

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-areas:
        "first first first first first first last last last last last last"
        ". . . . . . cta cta cta cta cta cta";
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      grid-template-areas:
        "first first first first first last last last last last . ."
        ". . . cta cta cta cta cta cta cta cta cta";
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      grid-template-areas: "first first first first last last last last cta cta cta cta";
    }

    .col-01,
    .col-02 {
      margin-bottom: var(--spacer-l);

      .news-article {
        width: 70%;
        margin: 0 auto;

        @media screen and (min-width: map-get($breakpoints, "medium-min")) {
          width: 100%;
        }
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        margin-bottom: 0;
      }
    }

    .col-01 {
      grid-area: first;
    }

    .col-02 {
      grid-area: last;
    }

    .col-03 {
      grid-area: cta;
      display: flex;
      justify-content: center;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        justify-content: flex-start;
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        align-items: flex-end;
      }
    }
  }
}
