/* SYNCED SLIDER */
.slider-container.slider--career {
  /* stylelint-disable selector-class-pattern */
  --swiper-theme-color: rgb(104 222 222 / 100%);
  --swiper-pagination-bullet-inactive-color: rgb(211 211 211 / 100%);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 1rem;
  --swiper-pagination-bullet-size: .5rem;
  --swiper-navigation-size: 3rem;

  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    --swiper-pagination-bullet-size: .75rem;
  }

  @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
    --swiper-navigation-size: 4rem;
  }

  .swiper {
    width: 100%;
    height: 100%;
    text-rendering: initial;

    &-wrapper {
      position: relative;
      height: auto;
      margin-bottom: 3rem;
    }

    &-slide {
      height: auto;
    }

    &-pagination {
      @media screen and (min-width: map-get($breakpoints, "large")) {
        padding: .625rem 0;
        text-align: left;
      }
    }

    &-button-prev {
      left: 0;

      //background-image: url("/src/assets/icons/swiper-arrow-prev.png");
      mask: url("/src/assets/icons/swiper-arrow-prev.png");

      @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
        grid-column-start: 5;
        left: -100px;
      }
    }

    &-button-next {
      right: 0;

      //background-image: url("/src/assets/icons/swiper-arrow-next.png");
      mask: url("/src/assets/icons/swiper-arrow-next.png");

      @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
        grid-column-start: -1;
        right: -100px;
      }
    }

    &-button-prev,
    &-button-next {
      position: absolute;
      display: none;
      top: initial;
      bottom: 0;
      width: var(--swiper-navigation-size);

      /* background-repeat: no-repeat;
      background-position: center;
      background-size: contain; */
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      opacity: 1;
      background-color: #000;
      transition: background-color 300ms ease-in-out;

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        display: block;
      }

      &::after {
        content: "";
      }

      &:hover,
      &:focus-visible {
        background-color: var(--swiper-theme-color);
      }
    }

    &--disabled {
      pointer-events: none;

      .swiper-wrapper {
        cursor: auto !important;
        transform: translate3d(0, 0, 0) !important;
      }

      ~ .slider-navigations {
        display: none;
      }
    }
  }

  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    margin: 0 auto .8rem;
    transform: translateX(0%);

    @media screen and (min-width: map-get($breakpoints, "large")) {
      margin: unset;
    }
  }

  .col {
    &-01 {
      grid-column: 1 /-1;

      @media screen and (min-width: map-get($breakpoints, "large")) {
        grid-column: 5 / -1;
      }
    }

    &-02 {
      grid-column: 1 /-1;

      @media screen and (min-width: map-get($breakpoints, "large")) {
        grid-column: 1 / 4;
      }
    }
  }

  // Image Slider
  .slider-img {
    position: relative;
    height: max-content;
    background: linear-gradient(0deg, transparent, rgb(255 255 255 / 85%) 10%, rgb(255 255 255 / 90%) 50%, rgb(255 255 255 / 85%) 90%, transparent);

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      aspect-ratio: 45 / 28; // typical image ar ...?
      overflow: hidden;

      > picture,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > picture > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  // Slider Controls
  .slider-navigations {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .swiper {
      &-button-prev,
      &-button-next {
        position: absolute;
        top: 50%;
      }

      &-button-prev {
        left: 0;
        transform: translate(-100%, -50%);
      }

      &-button-next {
        right: 0;
        transform: translate(100%, -50%);
      }

      &-pagination {
        position: absolute;
        bottom: 0 !important;
        left: 50% !important;
        transform: translate(-50%, 0) !important;
      }
    }
  }

  // Text Slider
  .slider-text {
    padding-top: var(--spacer-xs);

    .swiper-slide {
      display: flex;
      flex-direction: column;
      height: 100%;
      opacity: 0 !important;
      transition: opacity 400ms ease-in-out;

      @media screen and (min-width: map-get($breakpoints, "large")) {
        margin-top: 70%;
      }

      &[data-swiper-slide-index="0"] {
        @media screen and (min-width: map-get($breakpoints, "large")) {
          margin-top: 90%;
        }
      }

      &-active {
        opacity: 1 !important;
      }

      > .copy:nth-of-type(2) {
        background: linear-gradient(0deg, transparent, rgb(255 255 255 / 85%) 10%, rgb(255 255 255 / 90%) 50%, rgb(255 255 255 / 85%) 90%, transparent);
      }
    }
  }

  // Dark Theme
  &.swiper--dark {
    --swiper-pagination-bullet-inactive-color: rgb(255 255 255 / 100%);
    --swiper-pagination-bullet-inactive-opacity: .3;

    background: $color-black;
    .slider-img {
      background: none;
    }
    .slider-navigations {
      .swiper {
        &-button-prev,
        &-button-next {
          background-color: white;
        }
      }
    }
  }
}
