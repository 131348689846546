:root {
  --footer-row-space: 3.75rem;
  --footer-col-space: 2.2rem;

  // css var with #{} due to issue with postcss-calc https://github.com/postcss/postcss-calc/issues/77
  --footer-item-space: 2rem;
}

// helper blocks
.footer-back-to-top {
  position: absolute;
  top: 1.875rem;
  right: 1.5rem;
  transform: rotate(270deg);
  transform-origin: right;

  &__link {
    @include transition(color);

    margin-right: .5rem;
    color: $color-secondary;
    text-decoration: none;

    &:hover {
      color: $color-secondary-dark;
    }
  }

  svg {
    width: 12.5rem;
  }

  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    top: 14.5rem;
    right: 2.5rem;
  }
}

// main block
.footer {
  position: relative;
  padding: var(--spacer-l) 0;
  background: $color-black;

  &__logo {
    svg {
      width: 125px;
      height: auto;
    }
  }

  &__link {
    @include transition(color);

    display: inline-block;
    text-decoration: none;

    &:hover {
      color: $color-grey-light-2;
    }
  }

  &__link--icon {
    svg {
      width: 24px;
      height: auto;

      path {
        @include transition(fill);
      }

      &:hover {
        path {
          fill: $color-grey-light-2;
        }
      }
    }
  }

  .row-01 {
    margin-bottom: var(--footer-row-space);

    .col-01 {
      @include grid-column(1, -1);
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      margin-bottom: 6rem;
    }
  }

  .row-02 {
    margin-bottom: var(--footer-row-space);

    .col-01 {
      @include grid-column(1, -1, 1, 4);

      margin-bottom: var(--footer-col-space);
    }

    .col-02 {
      @include grid-column(1, -1, 5, 8);

      margin-bottom: var(--footer-col-space);
    }

    .col-03 {
      @include grid-column(1, -1, 9, 12);

      .footer {
        &__address,
        &__phone-email {
          margin-bottom: var(--footer-col-space);
        }
        &__gwa-logo {
          a {
            svg {
              height: auto;
              width: 150px;

              @media screen and (min-width: map-get($breakpoints, "medium-max")) {
                width: 180px;
              }

              path {
                @include transition(fill);
                &.cls-1 {
                  fill: $color-black;
                }
                &.cls-2 {
                  fill: $color-grey-dark-2;
                }
              }
            }

            &:hover svg path.cls-2 {
              fill: $color-grey-light-2;
            }
          }
        }
      }
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      margin-bottom: 10rem;
    }
  }

  .row-03 {
    grid-template-areas:
      "icons icons icons icons icons icons"
      "links links links links links links"
      "copy copy copy copy copy copy";

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-areas:
        ". . . . icons icons icons icons icons icons icons ."
        "copy copy copy copy links links links links links links links links";
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      grid-template-areas: "copy copy copy links links links . . icons icons icons icons";
    }

    .col-01 {
      grid-area: icons;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--footer-col-space);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        justify-content: flex-start;
        gap: 2.625rem;
        margin-bottom: 2rem;
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      grid-area: copy;
    }

    .col-03 {
      grid-area: links;
      margin-bottom: var(--footer-col-space);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        display: flex;
        margin-bottom: 0;

        .copy {
          margin-bottom: 0;
        }

        .copy:not(:last-child) {
          position: relative;
          margin-right: 2rem; // var(--footer-item-space);

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            /* stylelint-disable-next-line custom-property-no-missing-var-function */
            right: calc(#{var(--footer-item-space)} * -1 / 2);
            width: 2px;
            height: 80%;
            background: $color-grey-dark-2;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    padding-top: 7rem;
  }
}

// Required elements for animated main entrance
.footer-wrapper {
  position: relative;
}

.footer-extension {
  position: absolute;
  top: -1.6rem;
  left: 0;
  width: 100%;
  height: 1.6rem;
  background: $color-black;
  z-index: -1;
}
