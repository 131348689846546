@keyframes slide-in-up {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.main {
  &--space-top {
    padding-top: var(--header-height);
  }

  &--animated {
    padding-top: 0;
    margin-top: var(--header-height);
    background: $color-white;
    border-radius: 1.625rem;
    animation-name: slide-in-up;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(.19, 1, .22, 1);
    overflow: hidden;
    z-index: 1;
  }
}
