// helper blocks
.text-hero-label {
  display: flex;
  align-items: center;

  &__line {
    width: 2.0625rem;
    margin-right: .625rem;
    border-top: 1px solid $color-grey-dark-2;

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      width: 3.25rem;
      margin-right: 1.25rem;
    }
  }
}

// main block
.text-hero {
  margin: var(--spacer-xl) 0  var(--spacer-m);

  &__row-title {
    .col-01 {
      @include grid-column(1, -1, 1, 9);
    }
  }
}
