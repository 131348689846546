.feature-box {
  margin-bottom: var(--spacer-l);

  .row-01 {
    .col-01 {
      @include grid-column(1, -1, 3, -1, null, null, 4, -1);

      padding: var(--spacer-l);
    }
  }
}
