// helper blocks
.job-category-hero {
  .main-hero__row-title {
    .col-02 {
      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-top: 4rem;
      }
    }
  }
}

.job-category-cta {
  /* stylelint-disable-next-line no-descending-specificity */
  .col-02 {
    margin-top: 2rem;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      margin-top: 0;
    }
  }
}

// main block
.job-category-story {
  .row-01 {
    margin-bottom: 3rem;

    .col-01 {
      @include grid-column(1, -1, 1, 9);

      margin-bottom: 1rem;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(9, -1);
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      margin-bottom: 0;
    }
  }

  .row-02 {
    margin-bottom: 3rem;

    .col-01 {
      @include grid-column(1, -1, 8, -1, null, null, 7, -1);
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      margin: -3rem 0 4rem;
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      margin: -5rem 0 5rem;
    }
  }

  .row-03 {
    .col-01 {
      @include grid-column(1, -1, 1, 7);

      margin-bottom: 1rem;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(1, -1, 7, -1);
    }
  }
}

// job-categories subtext
.copy.copy--regular.job-categories-subtext {
  margin: var(--spacer-xs) 0 0;
  max-width: 525px;
}
