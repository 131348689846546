.text-with-aside-image-and-text {
  margin-bottom: var(--spacer-l);

  .row-01 {
    .col-01 {
      @include grid-column(2, -1);
    }
  }

  .row-02 {
    margin-bottom: var(--spacer-xs);

    .col-01 {
      @include grid-column(1, 4);
    }

    .col-02 {
      @include grid-column(4, -1);
    }
  }

  .row-03 {
    .col-01 {
      @include grid-column(2, -1);
    }
  }

  .row-04 {
    .col-01 {
      @include grid-column(null, null, 1, 4);

      .text-with-aside-image-and-text__picture {
        margin-bottom: var(--spacer-xs);
      }
    }

    .col-02 {
      @include grid-column(null, null, 4, -1, null, null, 5, -1);
    }
  }
}
