// headings
.heading {
  font-family: $text-font-stack-heading;
  letter-spacing: -.04em;

  // TODO review word break
  word-break: break-word;

  &--h1 {
    margin: 0 0 var(--spacer-xs);
    font-size: 2.5rem; // 40px
    line-height: 2.75rem; // 44px

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      font-size: 3.5rem; // 56px
      line-height: 3.75rem; // 60px
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      font-size: 5.625rem; // 90px
      line-height: 5.5rem; // 88px
    }
  }

  &--h2 {
    margin: 0 0 var(--spacer-xs);
    font-size: 1.375rem; // 22px
    line-height: 1.875rem; // 30px

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      font-size: 2.125rem; // 34px
      line-height: 2.625rem; // 42px
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      font-size: 3rem; // 48px
      line-height: 3.5rem; // 56px
    }
  }

  &--h3 {
    margin: 0 0 var(--spacer-xs);
    font-size: 1.125rem; // 18px
    line-height: 1.625rem; // 26px

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      font-size: 1.625rem; // 26px
      line-height: 2.125rem; // 34px
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      font-size: 2rem; // 32px
      line-height: 2.5rem; // 40px
    }
  }

  &--h4 {
    margin: 0 0 var(--spacer-xs);
    font-size: 1rem; // 16px
    line-height: 1.5rem; // 24px

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      font-size: 1.375rem; // 22px
      line-height: 1.75rem; // 28px
    }
  }
}

// body copy
.copy {
  letter-spacing: -.04em;

  &:last-child {
    margin-bottom: 0;
  }

  &--x-small {
    margin: 0 0 1rem;
    font-size: .875rem; // 14px
    line-height: 1.25rem; // 20px
  }

  &--small {
    margin: 0 0 1rem;
    font-size: 1rem; // 16px
    line-height: 1.5rem; // 24px

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      font-size: 1.125rem; // 18px
      line-height: 1.625rem; // 26px
    }
  }

  &--regular {
    margin: 0 0 1.2rem;
    font-size: 1.125rem; // 18px
    line-height: 1.625rem; // 26px

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      font-size: 1.375rem; // 22px
      line-height: 1.875rem; // 30px
    }
  }

  &--big {
    margin: 0 0 1.2rem;
    font-size: 1.125rem; // 18px
    line-height: 1.625rem; // 26px

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      font-size: 1.625rem; // 26px
      line-height: 2.125rem; // 34px
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      font-size: 2rem; // 32px
      line-height: 2.5rem; // 40px
    }
  }
}

// text utilities
.text-bold {
  font-family: $text-font-stack-heading;
}

.text-color {
  &-black {
    color: $color-black;
  }

  &-grey-light-1 {
    color: $color-grey-light-1;
  }

  &-grey-dark-2 {
    color: $color-grey-dark-2;
  }

  &-primary {
    color: $color-primary;
  }

  &-secondary {
    color: $color-secondary;
  }

  &-white {
    color: $color-white;
  }
}

.text-slider-bottom {
  @media screen and (min-width: map-get($breakpoints, "large")) {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: var(--spacer-xs);

    & :first-child {
      margin-top: auto;
    }

    & :last-child {
      margin-bottom: 3.125rem;

      @media screen and (min-width: map-get($breakpoints, "large")) {
        margin-bottom: 6.25rem;
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        margin-bottom: 7.5rem;
      }
    }
  }
}

.outline-heading {
  position: relative;
  font-family: $text-font-stack-heading;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: .125rem;
  -webkit-text-stroke-color: $color-grey-dark-2;
  font-size: 11.25rem; // 180px
  line-height: .8;

  &--primary {
    -webkit-text-stroke-color: $color-primary;
  }

  &--white {
    -webkit-text-stroke-color: $color-white;
  }

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    font-size: 7.5rem; // 120px
  }

  @media screen and (min-width: map-get($breakpoints, "large")) {
    font-size: 11.25rem; // 180px
  }
}
