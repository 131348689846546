// helper blocks
.outlinks-item {
  + .outlinks-item {
    margin-top: 1rem;

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      margin-top: 3.5rem;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  &__line {
    width: 2.0625rem;
    margin-right: .75rem;
    border-top: 1px solid $color-grey-dark-2;

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      width: 3.25rem;
    }
  }
}

// main block
.outlinks {
  background: $color-grey-dark-1;
}
