.case-study-creation {
  .row-02 {
    margin-bottom: var(--spacer-l);

    .col-01 {
      @include grid-column(1, 4, 1, 7);

      margin-bottom: var(--spacer-l);

      @media screen and (min-width: map-get($breakpoints, 'medium-min')) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(2, -1, 7, -1);
    }
  }

  .software-ag-vision-slider,
  .slider-container,
  .slider-text {
    background: transparent;
  }

  .software-ag-quotation-slider {
    .slider-container .col-01 {
      @include grid-column(2, -1, 4, -1, null, null, 5, -1);
    }
    .slider-text {
      display: none;
    }
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          aspect-ratio: unset!important;
          flex-direction: column;
          align-items: flex-start!important;
        }
      }
    }
    .slider-navigations {
      .swiper-pagination {
        overflow: visible!important;
      }
    }
  }
}

.case-study-strategy {
  .software-ag-vision-slider,
  .slider-container.swiper--dark,
  .slider-text {
    background: transparent;
  }
}

.main-hero__software-ag {
  .col-01 h1 {
    @media screen and (min-width: map-get($breakpoints, 'large')) {
      margin-bottom: 5rem;
    }
  }
}
