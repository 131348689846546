// main block
.card-image-text {
  @include transition(box-shadow);

  position: relative;
  border-radius: $border-radius-12;

  &:hover {
    box-shadow: $box-shadow-1;
  }

  &__picture {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__image {
    border-radius: $border-radius-12;
    object-fit: cover;
    aspect-ratio: 1/1;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      filter: brightness(.6);
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
    }
  }

  &__link {
    text-decoration: none;
  }

  &__content-wrapper {
    display: flex;
    width: 100%;
    margin-top: 1rem;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      position: absolute;
      bottom: 0;
      flex-direction: column;
      margin-top: 0;
      padding: 2.5rem;
    }
  }

  &__label {
    margin-bottom: .5rem;
    font-size: .875rem;
    display: flex;
    align-items: center;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      color: $color-primary;
    }
  }

  &__label-line {
    width: 2.5rem;
    margin-right: .5rem;
    border-top: 1px solid $color-black;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      border-color: $color-primary;
    }
  }

  &__text {
    @include line-clamp(4);

    font-weight: 700;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      @include line-clamp(6);

      margin-bottom: 1rem;
      color: $color-white;
    }
  }

  &__action {
    align-self: flex-end;
    flex: 0 0 auto;
    margin-left: .5rem;

    svg {
      @include transition(transform);

      width: 1.5rem;
      height: 1.5rem;

      path {
        stroke: $color-black;
      }

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        width: 2rem;
        height: 2rem;

        path {
          stroke: $color-white;
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    margin-bottom: 0;

    &:hover {
      .card-image-text__action {
        svg {
          transform: translateX(.625rem);
        }
      }
    }
  }
}
