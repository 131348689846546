// main block
.impressions-section {
  color: $color-white;

  .outline-heading {
    -webkit-text-stroke-color: $color-grey-dark-2;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      -webkit-text-stroke-color: $color-white;
    }
  }

  .copy {
    @media screen and (max-width: map-get($breakpoints, "medium-max")) {
      hyphens: auto;
    }
  }

  .row-01 {
    margin-bottom: var(--spacer-l);
  }

  .row-02 {
    margin-bottom: var(--spacer-l);

    .col-01 {
      @include grid-column(2, -1, 4, -1, null, null, 5, -1);

      margin-bottom: var(--spacer-s);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(2, -1, 1, 4, null, null, 2, 4);
    }
  }

  .row-03,
  .row-06 {
    grid-template-areas:
      ". mainImage mainImage mainImage mainImage mainImage"
      "copy copy copy secondaryImage secondaryImage secondaryImage";
    margin-bottom: var(--spacer-l);

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-areas:
        "mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage secondaryImage secondaryImage secondaryImage"
        "mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage copy copy copy";
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-areas:
        "mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage secondaryImage secondaryImage secondaryImage secondaryImage"
        "mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage copy copy copy copy";
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      grid-template-areas:
        "mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage secondaryImage secondaryImage secondaryImage secondaryImage"
        "mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage . copy copy copy";
    }

    .col-01 {
      grid-area: mainImage;
      margin-bottom: var(--spacer-m);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      grid-area: secondaryImage;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: var(--spacer-s);
      }

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        margin-bottom: var(--spacer-xs);
      }
    }

    .col-03 {
      grid-area: copy;
    }
  }

  .row-04 {
    grid-template-areas:
      ". mainImage mainImage mainImage mainImage mainImage"
      "secondaryImage secondaryImage secondaryImage copy copy copy";
    margin-bottom: var(--spacer-l);

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-areas:
        "copy copy copy mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage"
        ". . . secondaryImage secondaryImage secondaryImage . . . . . .";
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      grid-template-areas:
        ". copy copy . mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage"
        ". . . . secondaryImage secondaryImage secondaryImage  . . . . .";
    }

    .col-01 {
      grid-area: mainImage;
      margin-bottom: var(--spacer-m);
    }

    .col-02 {
      grid-area: secondaryImage;
    }

    .col-03 {
      grid-area: copy;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        align-self: end;
        margin-bottom: var(--spacer-s);
      }
    }
  }

  .row-05 {
    grid-template-areas:
      ". mainImage mainImage mainImage mainImage mainImage"
      "secondaryImage secondaryImage secondaryImage copy copy copy";
    margin-bottom: var(--spacer-l);

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-areas:
        "mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage secondaryImage secondaryImage secondaryImage"
        "mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage copy copy copy";
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-areas:
        "secondaryImage secondaryImage secondaryImage secondaryImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage"
        "copy copy copy copy mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage";
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      grid-template-areas:
        "secondaryImage secondaryImage secondaryImage . mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage"
        "copy copy copy . mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage";
    }

    .col-01 {
      grid-area: mainImage;
      margin-bottom: var(--spacer-m);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      grid-area: secondaryImage;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: var(--spacer-s);
      }

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        margin-bottom: var(--spacer-xs);
      }
    }

    .col-03 {
      grid-area: copy;
    }
  }

  .row-06 {
    margin-bottom: 0;
  }
}
