// main block
.contact-section {
  &__picture {
    margin-bottom: var(--spacer-s);
  }

  .row-02 {
    .col-02 {
      @include grid-column(2, -1, 4, 8, 4, 7, 5, 7);

      margin-bottom: 1.2rem;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-03 {
      @include grid-column(2, -1, 8, 12, 7, 11);
    }
  }

  .row-03 {
    .col-01 {
      @include grid-column(1, -1, 3, -1, null, null, 5, -1);
    }
  }

  .row-04 {
    .col-01,
    .col-02 {
      margin-bottom: var(--spacer-m);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-01 {
      @include grid-column(2, -1, 1, 5);
    }

    .col-02 {
      @include grid-column(2, -1, 5, 9);
    }

    .col-03 {
      @include grid-column(2, -1, 9, -1);
    }
  }
}
