// helper blocks
.job-category-jobs-list {
  list-style: none;

  &__item {
    margin-top: 1rem;
  }

  &__link {
    margin-bottom: 1.5rem;

    svg {
      flex: 0 0 auto;
    }
  }
}

// main block
.job-category {
  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    &__title {
      margin-bottom: 2.5rem;
    }
  }
}
