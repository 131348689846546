.single-image-with-aside-text {
  margin-bottom: var(--spacer-l);

  .row-01 {
    .col-01 {
      @include grid-column(2, -1, 4, -1, null, null, 5, -1);
    }
  }

  .row-02 {
    .col-01 {
      @include grid-column(2, -1, 4, -1, null, null, 5, -1);

      margin-bottom: var(--spacer-s);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(2, -1, 1, 4, null, null, 2, 4);
    }
  }
}
