// main block
.news-article {
  @include transition(box-shadow);

  position: relative;
  grid-column: 1 / -1;
  aspect-ratio: 1/1;
  border-radius: $border-radius-12;

  &:hover {
    box-shadow: $box-shadow-1;
  }

  &__image {
    height: 100%;
    border-radius: $border-radius-12;
    object-fit: cover;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      filter: brightness(.6);
    }
  }

  &__link {
    text-decoration: none;
  }

  &__content-wrapper {
    display: flex;
    width: 100%;
    margin-top: 1rem;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      position: absolute;
      bottom: 0;
      flex-direction: column;
      margin-top: 0;
      padding: 2.5rem;
    }
  }

  &__label {
    margin-bottom: .5rem;
    font-size: .875rem;
    display: flex;
    align-items: center;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      color: $color-primary;
    }
  }

  &__label-line {
    width: 2.5rem;
    margin-right: .5rem;
    border-top: 1px solid $color-black;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      border-color: $color-primary;
    }
  }

  &__text {
    @include line-clamp(4);

    font-weight: 700;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      @include line-clamp(6);

      margin-bottom: 1rem;
      color: $color-white;
    }
  }

  &__action {
    align-self: flex-end;
    flex: 0 0 auto;
    margin-left: .5rem;

    svg {
      @include transition(transform);

      width: 1.5rem;
      height: 1.5rem;

      path {
        stroke: $color-black;
      }

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        width: 2rem;
        height: 2rem;

        path {
          stroke: $color-white;
        }
      }
    }
  }

  &--is-empty {
    display: none;
    background: transparent;
    pointer-events: none;
  }

  &--hide-image {
    height: 265px;
    background: $color-black;
    aspect-ratio: auto;
    border-radius: $border-radius-12;
    overflow: hidden;

    .news-article__image {
      opacity: 0;
    }

    .news-article__content-wrapper {
      margin-top: 0;
      padding: 1.5rem;
    }

    .news-article__label {
      color: $color-primary;
    }

    .news-article__label-line {
      border-color: $color-primary;
    }

    .news-article__text {
      @include line-clamp(4);

      color: $color-white;
    }

    .news-article__action {
      svg {
        path {
          stroke: $color-white;
        }
      }
    }
  }

  &--extended {
    aspect-ratio: auto;

    .news-article__image {
      width: 100%;
      height: auto;
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      .news-article__text {
        font-size: 2rem; // 32px
        line-height: 2.5rem; // 40px
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    grid-column: auto / span 5;
    margin-bottom: 0;

    &:hover {
      .news-article__action {
        svg {
          transform: translateX(.625rem);
        }
      }
    }

    &--is-empty {
      display: block;
      grid-column: auto / span 2;
    }

    &--hide-image {
      height: auto;
      aspect-ratio: 1/1;

      .news-article__text {
        @include line-clamp(6);
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, "large")) {
    grid-column: auto / span 4;

    &--is-empty {
      display: block;
      grid-column: auto / span 4;
    }
  }
}
