// helper blocks

// back to job-category link
.container.back-to-job-category-link {
  .col.col-01 {
    padding: 75px 0 0;

    @include grid-column(1, -1, null, null, 2, -1, 3, -1);

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      padding: 150px 0 0;
    }

    h2.heading.heading--h2 {
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin: 0;

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        font-size: 2.75rem;
        line-height: 3.25rem;
      }

      a svg {
        @media screen and (min-width: map-get($breakpoints, "medium-max")) {
          height: 60px;
          width: 60px;
        }
      }
    }
  }
}
