.leadec-global-employer-branding-special-row {
  .col-01 {
    order: 2;

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      order: 1;
    }
  }

  .col-02 {
    order: 1;
    grid-column-start: 2;
    margin-bottom: var(--spacer-s);
    display: flex;

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      grid-column-start: 1;
      order: 2;
      margin-bottom: 0;
      padding-right: 3rem;
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      padding-right: 6rem;
    }
  }

  &__picture {
    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      margin-left: auto;
      margin-bottom: 0;
    }
  }

  &__image {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      width: 8.75rem;
      height: 8.75rem;
    }
  }
}
