.simple-link-list {
  margin-bottom: var(--spacer-l);

  &__list {
    list-style: none;
  }

  &__list-item {
    display: flex;
    align-items: center;

    svg {
      width: 1.5rem;
      height: auto;
      flex: 0 0 auto;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        width: 2rem;
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        width: 2.5rem;
      }
    }
  }
}
