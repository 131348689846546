// helper blocks
.case-study-hero {
  .main-hero {
    &__row-title {
      .col-01 {
        .heading {
          @media screen and (min-width: map-get($breakpoints, "large")) {
            font-size: 2rem; // 32px
            line-height: 2.5rem; // 40px
          }
        }

        .heading.heading--h1-large {
          @media screen and (min-width: map-get($breakpoints, "large")) {
            font-size: 5.625rem; // 90px
            line-height: 5.5rem; // 88px
          }
        }
      }

      .col-02 {
        @media screen and (min-width: map-get($breakpoints, "medium-min")) {
          margin-top: -2.5rem;
        }
      }
    }
  }
}
