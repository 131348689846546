// main block
.podular-teaser {
  padding: var(--spacer-l) 0 var(--spacer-xl);
  margin-bottom: var(--spacer-xl);
  background: $color-black;
  color: $color-white;

  .copy {
    margin-bottom: 0;
  }

  // title
  .row-01 {
    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      margin-bottom: var(--spacer-l);
    }

    .col-01 {
      @include grid-column(1, -1, 4, -1, null, null, 5, -1);
    }
  }

  .row-02 {
    grid-template-areas:
      ". b b b b b"
      "a a a a a a";

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-areas:
        ". . . b b b b b b b b b"
        "a a a a a a a a a a a a";
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      grid-template-areas: "a a a . b b b b b b b b";
    }

    // list items
    .col-01 {
      grid-area: a;

      .sub-row {
        .sub-col-01 {
          @include grid-column(1, -1);

          @media screen and (min-width: map-get($breakpoints, "medium-min")) {
            display: flex;
            gap: 0;
          }
        }

        .sub-col-02 {
          @include grid-column(1, -1, 4, -1, null, null, 1, -1);
        }
      }

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        display: flex;
        flex-direction: column;
        margin-bottom: unset;
      }
    }

    // animation / illustration
    .col-02 {
      grid-area: b;
      margin: var(--spacer-l) 0 var(--spacer-l);

      svg {
        width: 100%;
        height: auto;
      }

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        margin: 0 0 var(--spacer-l);
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        margin: 0;
      }
    }
  }

  &__copy-item {
    margin-bottom: var(--spacer-l);
    pointer-events: none;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      margin-bottom: 0;
      pointer-events: all;

      .podular-teaser {
        &__copy-text {
          height: 0;
          overflow: hidden;
          opacity: 0;
        }
      }

      &--is-active {
        margin-bottom: auto;
        pointer-events: none;

        .podular-teaser {
          &__copy-title-line {
            display: block;
            width: 7.125rem;
            margin-right: 1rem;
            border-color: $color-secondary;
            opacity: 1;
          }

          &__copy-title {
            color: $color-secondary;
          }

          &__copy-text {
            height: 100%;
            margin-bottom: 2rem;
            opacity: 1;
          }
        }
      }
    }
  }

  &__copy-title-wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: var(--spacer-xs);

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      margin-bottom: 1rem;
    }
  }

  &__copy-title-line {
    @include transition(width);
    @include transition(opacity);

    flex: 0 0 auto;
    width: 2.1875rem;
    border-top: 1px solid $color-white;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      opacity: 0;
      width: 0;
    }
  }

  &__copy-title {
    @include transition(color);

    background: transparent;
    color: $color-white;
    text-align: left;
    border: none;
    cursor: pointer;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      color: $color-grey-dark-2;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      &:hover {
        color: $color-white;
      }
    }
  }

  &__copy-text {
    @include transition(opacity);
  }
}
