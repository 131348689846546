.dual-image-overlap {
  margin-bottom: var(--spacer-l);

  .row-01 {
    .col-01 {
      @include grid-column(2, -1, 4, -1, null, null, 5, -1);
    }
  }

  .row-02 {
    grid-template-areas:
      "mainImage mainImage mainImage mainImage . ."
      "text text text text . ."
      ". . asideImage asideImage asideImage asideImage"
      ". . asideText asideText asideText asideText";

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-areas:
        "mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage text text text ."
        ". . . asideText asideText asideText asideImage asideImage asideImage asideImage asideImage asideImage";
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      grid-template-areas:
        "mainImage mainImage mainImage mainImage mainImage mainImage mainImage mainImage text text text ."
        ". . . . asideText asideText asideText asideImage asideImage asideImage asideImage asideImage";
    }

    .col-01 {
      grid-area: mainImage;
      margin-bottom: var(--spacer-xs);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      grid-area: asideImage;
      margin-top: var(--spacer-s);
      margin-bottom: var(--spacer-xs);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-top: calc(#{var(--spacer-l)} * -1);
        margin-bottom: 0;
      }
    }

    .col-03 {
      grid-area: text;
      margin-bottom: var(--spacer-xs);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-04 {
      grid-area: asideText;
      margin-top: auto;
    }
  }
}
