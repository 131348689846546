.number-highlights {
  padding: var(--spacer-l) 0;
  background: $color-black;

  &__wrapper {
    display: grid;
    gap: var(--spacer-xs);

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      gap: 0;
    }
  }

  &__item {
    text-align: center;
  }

  &__number {
    margin-bottom: 1rem;
    color: $color-primary;
  }

  &__label {
    color: $color-white;
  }
}
