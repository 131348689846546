// main block
.outline-teaser {
  background: $color-black;

  .row-01 {
    .col-01 {
      @include grid-column(2, -1, null, null, 3, -1);

      position: absolute;

      .outline-heading {
        font-size: 18.75rem;
        line-height: 1;

        @media screen and (min-width: map-get($breakpoints, "medium-max")) {
          font-size: 31.25rem;
        }
      }
    }

    .col-02 {
      @include grid-column(1, -1, null, null, 4, -1);

      margin-top: 8.125rem;

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        margin-top: 14.375rem;
      }
    }
  }
}
