// helper blocks
/* stylelint-disable-next-line selector-class-pattern */
.header.header--dark:not(.header--is-open) {
  .header-logo {
    svg {
      path {
        fill: $color-white;
      }
    }
  }

  .header-menu {
    .header-menu-btn {
      color: $color-white;

      svg {
        path {
          stroke: $color-white;
        }
      }
    }
  }
}

.header-logo {
  display: inline-flex;
  text-decoration: none;

  svg {
    width: 5.625rem;
    height: auto;

    path {
      fill: $color-black;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      width: 7.8125rem;
    }
  }
}

.header-notification {
  @include transition(opacity);
  @include transition(max-height);
  @include transition(transform);

  max-height: 0;
  margin-bottom: 0;
  opacity: 0;

  &__content {
    @include grid-column(1, -1);

    position: relative;
    padding: 1.0625rem;
    border: 1px solid $color-black;
    border-radius: $border-radius-8;
  }

  &__btn {
    @include transition(transform);

    position: absolute;
    right: 1.125rem;
    top: .8125rem;
    background: transparent;
    border: none;
    cursor: pointer;

    &:active {
      transform: scale(.9);
    }

    svg {
      width: .625rem;
      height: auto;
    }
  }

  &:not(&--is-open) {
    transform: translateY(-3.875rem);
  }

  &--is-open {
    max-height: unset;
    margin-top: 1rem;
    margin-bottom: 1rem;
    opacity: 1;
  }
}

.header-menu {
  &__btn {
    display: inline-flex;
    background: transparent;
    border: none;
    cursor: pointer;

    svg {
      width: 1.125rem;
      height: auto;
    }

    span {
      display: none;
      font-size: 1.375rem;

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        display: inline-block;
      }
    }
  }

  &--closed {
    svg {
      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        display: none;
      }
    }
  }

  &--opened {
    display: none;

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      span {
        margin-right: 3rem;
      }

      svg {
        position: relative;
        top: .125rem;
      }
    }
  }
}

.header-info {
  @include line-clamp(1);

  margin: 0 1.5rem;

  &__item {
    margin-bottom: 0;
    line-height: 1.375rem;
  }
}

// main block
.header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  background: transparent;
  transition: transform .4s, background 180ms ease-out;

  &--dark {
    background: $color-black;
  }

  &--primary {
    background: $color-primary;
  }

  &--scroll-down {
    transform: translate3d(0, -100%, 0);
  }

  &--scroll-up {
    transform: none;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &--scroll-up:not(.header--is-open) {
    background: $color-primary;
    box-shadow: 0 -3px 14px rgb(0 0 0 / 35%);
  }

  &--scroll-up.header--dark:not(.header--is-open) {
    background: $color-black;
  }

  &__wrapper {
    @include grid-column(1, -1);

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .9rem 0 .6rem;

    /* stylelint-disable-next-line selector-class-pattern */
    .header__col {
      &--logo {
        display: flex;
        align-items: center;
      }

      &--actions {
        display: flex;
        align-items: center;
      }
    }
  }

  &--is-open {
    .header-logo {
      svg {
        path {
          fill: $color-black;
        }
      }
    }

    .header-info {
      display: none;
    }

    .header-menu {
      &--closed {
        display: none;
      }
    }

    /* stylelint-disable-next-line no-duplicate-selectors */
    .header-menu {
      &--opened {
        display: block;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    padding: 1.1rem 0 1rem;
  }
}

// Required element for animated main entrance
.header-extension {
  position: absolute;
  top: var(--header-height);
  left: 0;
  width: 100%;
  height: 1.6rem;
  background: $color-primary;

  &--dark {
    background: $color-black;
  }
}
