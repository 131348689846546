/* stylelint-disable custom-property-no-missing-var-function */
// css var with #{} due to issue with postcss-calc https://github.com/postcss/postcss-calc/issues/77
:root {
  --language-switcher-item-space: 1rem;

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    --language-switcher-item-space: 2rem;
  }
}

// helper blocks
/* stylelint-disable-next-line selector-class-pattern */
.header.header--dark:not(.header--is-open) {
  .language-switcher {
    &__list-item {
      &:first-child:not(:only-child) {
        &::after {
          background: $color-white;
        }
      }
    }

    &__link {
      color: $color-white;
    }
  }
}

// main block
.language-switcher {
  margin-right: 1.5rem;

  &__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__list-item {
    margin: 0;

    &:first-child:not(:only-child) {
      position: relative;
      margin-right: var(--language-switcher-item-space);

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: calc(#{var(--language-switcher-item-space)} * -1 / 2);
        width: 2px;
        height: 80%;
        background: $color-black;
        transform: translateY(-50%);
      }
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      margin-right: var(--language-switcher-item-space);

      &::after {
        right: calc(#{var(--language-switcher-item-space)} * -1 / 2);
      }
    }
  }

  &__link {
    color: $color-black;
    font-size: .875rem; // 14px
    text-decoration: none;

    &:hover,
    &--is-active {
      text-decoration: underline;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      font-size: 1.375rem; // 22px
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    margin-right: 2.5rem;
  }
}
