// main block
.customer-logos {
  margin-bottom: var(--spacer-xl);

  &__logo-picture {
    filter: grayscale(1);
  }

  &__logo-image {
    max-height: 4rem;
  }

  .row-01 {
    margin-bottom: var(--spacer-l);

    .col-01 {
      @include grid-column(1, -1, 4, -1);
    }
  }

  .row-02 {
    .col-01 {
      @include grid-column(1, -1);

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
      justify-items: center;
      align-items: center;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        grid-template-columns: repeat(4, 1fr);
        gap: 3rem;
      }

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        grid-template-columns: repeat(5, 1fr);
        gap: 4rem;
        padding: 0 15%;
      }
    }
  }
}
