.new-social-page {
  background: $color-grey-dark-1;

  .main-hero__row-title--reverse .col-01 {
    @include grid-column(1, -1, 1, -1, 1, 12);
  }

  #hero-video-player-wrapper {
    cursor: pointer;

    &:after {
      content: '';

      --player-button-size: 156px;

      border-radius: 50%;
      width: var(--player-button-size);
      height: var(--player-button-size);
      aspect-ratio: 1;

      background: url(../svg/enlarge.svg), $color-primary;

      background-position: center;
      background-size: 100px;
      background-repeat: no-repeat;

      position: absolute;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover:after {
      opacity: 1;
    }
  }

  #hero-video-player-wrapper * {
    pointer-events: none;
  }

  .button-group {
    display: flex;
    gap: var(--spacer-xs);
    margin: var(--spacer-xs) 0;
  }

  .sticky-navigation {
    @media screen and (min-width: map-get($breakpoints, 'medium-min')) {
      width: auto;
    }

    @media screen and (max-width: map-get($max-width-breakpoints, 'large')) {
      display: none;
    }
  }

  section.offset-layout {
    .container {
      row-gap: var(--spacer-l);
    }

    .row-02 {
      margin-bottom: var(--spacer-l);

      .col-01 {
        @include grid-column(1, -1, 1, 10, 1, 7);

        margin-bottom: var(--spacer-l);

        @media screen and (min-width: map-get($breakpoints, 'medium-min')) {
          margin-bottom: 0;
        }
      }

      .col-02 {
        @include grid-column(1, -1, 5, -1);
      }
    }

    &:nth-child(odd) {
      .row-01 {
        .col-01 {
          @include grid-column(1, -1, 1, -1, 1, 8);
        }

        .col-02 {
          @include grid-column(1, -1, 1, -1, 9, -1);

          @media screen and (min-width: map-get($breakpoints, 'large')) {
            margin-top: calc(-1 * var(--spacer-l));
          }
        }
      }
    }
  }

  section.register-form {
    padding: var(--spacer-xl) 0;

    .row-01 {
      margin-bottom: var(--spacer-m);

      .col-01 {
        @include grid-column(1, -1, null, null, 1, -3);
      }
    }

    .row-02 {
      .col-01 {
        @include grid-column(1, -1, null, null, 1, -3);
      }
    }
  }

  section.collaboration {
    padding: var(--spacer-xl) 0;
  }

  .double-grid {
    .col-text,
    .col-media {
      grid-column: 1 / -1;
    }

    .col-media {
      @media screen and (max-width: map-get($max-width-breakpoints, 'medium-max')) {
        max-width: 600px;
        margin-right: auto;
        order: 1;

        margin-top: var(--spacer-m);
      }
    }

    .row {
      row-gap: var(--spacer-xs);
    }

    @media screen and (min-width: map-get($breakpoints, 'large')) {
      .col-text {
        grid-column: span 6;

        margin-top: var(--spacer-l);
      }

      .col-media {
        grid-column: span 4;
      }

      .row:nth-child(even) {
        .col-text {
          order: -1;
        }

        .col-media {
          grid-column: 8 / -1;
        }
      }

      .row:nth-child(odd) {
        .col-media {
          order: -1;
        }

        .col-text {
          order: 1;
          grid-column: 6 / -1;
        }
      }
    }
  }

  section.main-teaser .col-01 {
    @include grid-column(1, -1, 4, -1, null, null, 5, -1);
  }
}
