.internal-communication-page {
  .section-three {
    .row-02 {
      .col-01 {
        margin-bottom: var(--spacer-xs);

        @media screen and (min-width: map-get($breakpoints, "medium-min")) {
          margin-bottom: 0;
        }
      }
    }
  }

  .section-four {
    .row-02 {
      .col-01 {
        margin-bottom: var(--spacer-xs);

        @media screen and (min-width: map-get($breakpoints, "medium-min")) {
          margin-bottom: 0;
        }
      }
    }
  }
}
