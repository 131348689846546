// helper blocks
.modal-content-media {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    cursor: pointer;
  }

  &__play-btn {
    @include transition(opacity);

    position: absolute;
    border: none;
    background: transparent;
    pointer-events: none;

    &:hover {
      opacity: .85;
    }

    &--is-hidden {
      opacity: 0;
    }

    > svg {
      width: 3.125rem;
      height: auto;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        width: 5rem;
      }

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        width: auto;
      }
    }
  }
}

.modal-content-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0 2rem;
  padding: 35px 35px 40px;
  background-color: $color-white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    //gap: 0 3rem;
  }

  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    //gap: 0 4rem;
    flex-direction: row;
  }

  @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
    gap: 0 3rem;
  }

  &__col {
    flex: 1;

    h2 {
      font-size: 3rem;
      line-height: 3rem;

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        margin: 0;
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        font-size: 3.25rem;
        line-height: 3.25rem;
      }

      @media screen and (min-width: map-get($breakpoints, "extra-large")) {
        font-size: 4.0rem;
        line-height: 4.0rem;
      }

      @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
        font-size: 4.25rem;
        line-height: 4.25rem;
      }
    }
  }

  &__col-2 {
    padding-top: 3px;
  }

  &--col2-small {
    .modal-content-text {
      &__col {
        flex: initial;
      }
    }
  }
}

// main block
.modal-content {
  display: none;
  z-index: 2;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0 0 0 / 75%);
    padding: 60px 0;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      padding: 75px 0;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      padding: 90px 0;
    }

    @media screen and (min-width: map-get($breakpoints, "extra-large")) {
      //padding: 110px 0;
    }

    @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
      padding: 125px 0;
    }
  }

  &__body {
    position: relative;
    background: $color-white;
    width: 95vw;
    height: auto;
    margin: 0;
    border-radius: 1.875rem;
    z-index: 3;
    min-width: 340px;

    @media screen and (min-width: map-get($breakpoints, "small")) {
      width: 70vw;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      width: 55vw;
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      width: 45vw;
    }

    @media screen and (min-width: 1480px) {
      width: 40vw;
    }

    @media screen and (min-width: 1800px) {
      width: 37.5vw;
    }

    @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
      width: 35vw;
    }
  }

  &__media {
    width: 100%;
    aspect-ratio: 4 / 3;
    border-radius: 1.875rem;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      aspect-ratio: 3 / 2;
    }
  }

  &__btn {
    @include transition(opacity);

    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    background: $color-white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    z-index: 1;

    > svg {
      width: 15px;
      height: auto;
    }

    &:hover {
      opacity: .85;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      top: 15px;
      right: 15px;
      height: 50px;
      width: 50px;

      > svg {
        width: 25px;
      }
    }
  }

  &--is-open {
    display: block;

    .modal-content {
      &__overlay {
        overflow-y: auto;
      }
    }
  }
}
