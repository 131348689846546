.number-heading-teaser {
  &__item {
    @include grid-column(2, -1, 4, -1, null, null, 5, -1);

    position: absolute;
    bottom: 0;
    font-family: $text-font-stack-heading;
    font-size: 300px;
    line-height: 230px;
    color: $color-black;
    transform: translate(0, 13px);

    &.scroll-tween--is-active {
      transition: top 50ms ease-in-out;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      font-size: 430px;
      line-height: 430px;
      transform: translate(-40px, 30px);
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      font-size: 500px;
      line-height: 500px;
      transform: translate(-80px, 40px);
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      transform: translate(-75px, 40px);
    }

    ~ .col {
      z-index: 1;
    }
  }

  .row-01 {
    padding-top: 140px;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      padding-top: 270px;
    }
  }
}
