/**
* Base styles and reset based on https://piccalil.li/blog/a-modern-css-reset/
* and https://github.com/KittyGiraudel/sass-boilerplate
*/

* {
  margin: 0;
  padding: 0;
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
.body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100vw;
  min-width: 320px;
  font-family: $text-font-stack-primary;
  color: $color-black;
  text-rendering: optimizespeed;
  -webkit-font-smoothing: antialiased;

  &--animated-dark {
    background: $color-black;
  }

  &--animated-primary {
    background: $color-primary;
  }

  @supports (-webkit-text-rendering: geometricPrecision) or (text-rendering: geometricPrecision) {
    text-rendering: geometricprecision;
  }
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

ul {
  list-style-position: inside;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: .01ms !important;
    scroll-behavior: auto !important;
  }
}
