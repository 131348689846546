// helper blocks
.main-hero-label {
  display: flex;
  align-items: center;

  &__line {
    width: 2.0625rem;
    margin-right: .625rem;
    border-top: 1px solid $color-primary;

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      width: 3.25rem;
      margin-right: 1.25rem;
    }
  }
}

.main-hero-highlights {
  padding: 1.5rem;
  background: $color-primary;
  border-radius: .75rem;

  &__block {
    + .main-hero-highlights {
      &__block {
        margin-top: 2rem;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 2.5rem;
  }
}

.main-hero-aside-space-top-col {
  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    margin-top: 4rem;
  }
}

// main block
.main-hero {
  padding-bottom: var(--spacer-xl);

  &--dark {
    background: $color-grey-dark-1;
  }

  &__row-image {
    .col-01 {
      @include grid-column(1, -1);
    }

    .main-hero-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      min-height: 25rem;
      max-height: 80vh;
    }
  }

  &__row-title {
    .col-01 {
      @include grid-column(1, -1, 4, -1, null, null, 5, -1);

      margin: -5.5rem 0 2.5rem;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin: -5.5rem 0 0;
      }

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        margin: -6rem 0 0;
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        margin: -8rem 0 0;
      }
    }

    .col-02 {
      @include grid-column(1, -1, 1, 4, null, null, 1, 5);
    }
  }

  &__row-title--reverse {
    .col-01 {
      @include grid-column(1, -1, 1, 10, null, null, 1, 9);
    }

    .col-02 {
      @include grid-column(1, -1, 10, -1);
    }
  }
}
