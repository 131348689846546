/**
* Font family
*/
/* stylelint-disable-next-line value-keyword-case */
$text-font-stack-primary: 'ABCWhyte Regular', 'Arial', sans-serif;
/* stylelint-disable-next-line value-keyword-case */
$text-font-stack-heading: 'ABCWhyte Bold', 'Arial', sans-serif;

/**
* Spacing
*/
:root {
  --header-height: 2.7rem;
  --spacer-xxs: 0.5rem; // 8px
  --spacer-xs: 1.5rem; // 24px
  --spacer-s: 1.5rem; // 24px
  --spacer-m: 2.5rem; // 40px
  --spacer-l: 3.5rem; // 56px
  --spacer-xl: 4.5rem; // 72px

  @media screen and (min-width: map-get($breakpoints, 'medium-max')) {
    --header-height: 5.5rem;
    --spacer-xs: 2.5rem; // 40px
    --spacer-s: 3.5rem; // 56px
    --spacer-m: 4.5rem; // 72px
    --spacer-l: 6rem; // 96px
    --spacer-xl: 10rem; // 160px
  }
}

/**
* Colors
*/
$color-black: rgb(0 0 0 / 100%);
$color-grey-dark-1: rgb(21 21 22 / 100%);
$color-grey-dark-2: rgb(102 102 102 / 100%);
$color-grey-light-1: rgb(211 211 211 / 100%);
$color-grey-light-2: rgb(245 245 245 / 100%);
$color-online: rgb(131 253 100 / 100%);
$color-primary: rgb(245 223 77 / 100%);
$color-secondary: rgb(104 222 222 / 100%);
$color-secondary-dark: rgb(86 203 203 / 100%);
$color-secondary-darker: rgb(51 149 149 / 100%);
$color-white: rgb(255 255 255 / 100%);
$color-error: rgb(140 0 0);

/**
* Borders
*/
$border-radius-8: 8px;
$border-radius-10: 10px;
$border-radius-12: 12px;

/**
* Shadows
*/
$btn-shadow: 0 2px 24px 0 rgb(0 0 0 / 25%);
$btn-shadow-hover: 0 2px 12px 0 rgb(0 0 0 / 30%);
$box-shadow-1: 0 6px 24px 0 rgb(0 0 0 / 30%);
