.dual-image-small-aside {
  margin-bottom: var(--spacer-l);

  .row-01 {
    .col-01 {
      @include grid-column(2, -1, 4, -1, null, null, 5, -1);
    }
  }

  .row-02 {
    margin-bottom: var(--spacer-s);

    .col-01 {
      @include grid-column(2, -1, 4, -1, null, null, 5, -1);
    }

    .col-02 {
      @include grid-column(2, -1, 1, 4, null, null, 1, 5);

      .dual-image-small-aside__picture {
        margin-bottom: var(--spacer-xs);
      }
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      margin-bottom: 0;
    }
  }

  .row-03 {
    .col-01 {
      @include grid-column(1, 4);
    }

    .col-02 {
      @include grid-column(4, -1);
    }
  }

  &--is-reverse {
    .row-02 {
      .col-01 {
        @include grid-column(2, -1, 1, 10);
      }

      .col-02 {
        @include grid-column(2, -1, 10, -1);
      }
    }

    .row-03 {
      .col-01 {
        @include grid-column(1, 4);
      }

      .col-02 {
        @include grid-column(4, -1);
      }
    }
  }
}
