.image-border-radius-10 {
  border-radius: $border-radius-10;
}

.overflow-wrapper {
  overflow: hidden;
}

// Background
.bkg-primary {
  background: $color-primary !important;
  opacity: 1;
  transition: all 200ms;
}

.bkg-color-grey-dark-1 {
  background: $color-grey-dark-1 !important;
  opacity: 1;
  transition: all 200ms;
}

// Spacing
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-xs {
  margin-bottom: var(--spacer-xs) !important;
}

.mb-s {
  margin-bottom: var(--spacer-s) !important;
}

.mb-m {
  margin-bottom: var(--spacer-m) !important;
}

.mb-l {
  margin-bottom: var(--spacer-l) !important;
}

.mb-xl {
  margin-bottom: var(--spacer-xl) !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-xs {
  margin-top: var(--spacer-xs) !important;
}

.mt-s {
  margin-top: var(--spacer-s) !important;
}

.mt-m {
  margin-top: var(--spacer-m) !important;
}

.mt-l {
  margin-top: var(--spacer-l) !important;
}

.mt-xl {
  margin-top: var(--spacer-xl) !important;
}

.my-0-medium-min-down {
  @media screen and (max-width: map-get($max-width-breakpoints, "medium-min")) {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-xs {
  padding-bottom: var(--spacer-xs) !important;
}

.pb-s {
  padding-bottom: var(--spacer-s) !important;
}

.pb-m {
  padding-bottom: var(--spacer-m) !important;
}

.pb-l {
  padding-bottom: var(--spacer-l) !important;
}

.pb-xl {
  padding-bottom: var(--spacer-xl) !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-xs {
  padding-top: var(--spacer-xs) !important;
}

.pt-s {
  padding-top: var(--spacer-s) !important;
}

.pt-m {
  padding-top: var(--spacer-m) !important;
}

.pt-l {
  padding-top: var(--spacer-l) !important;
}

.pt-xl {
  padding-top: var(--spacer-xl) !important;
}

// Scroll lock
.scroll-lock {
  height: 100%;
  overflow: hidden;
}

// Breakpoint down display
.is-hidden-small-down {
  @media screen and (max-width: map-get($max-width-breakpoints, "small")) {
    display: none !important;
  }
}

.is-hidden-medium-min-down {
  @media screen and (max-width: map-get($max-width-breakpoints, "medium-min")) {
    display: none !important;
  }
}

.is-hidden-medium-max-down {
  @media screen and (max-width: map-get($max-width-breakpoints, "medium-max")) {
    display: none !important;
  }
}

// Breakpoint up display utilities
.is-hidden-small-up {
  @media screen and (min-width: map-get($breakpoints, "small")) {
    display: none !important;
  }
}

.is-hidden-medium-min-up {
  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    display: none !important;
  }
}

.is-hidden-medium-max-up {
  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    display: none !important;
  }
}

// no-white-space
.no-white-space {
  white-space: nowrap;
}
