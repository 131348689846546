/* stylelint-disable selector-class-pattern */
// helper blocks
.sticky-navigation-item {
  display: inline-block;
  margin-right: 1rem;
  padding-bottom: 0;
  text-decoration: none;
  transition: padding .3s .15s cubic-bezier(.075, .82, .165, 1);

  &__line {
    @include transition(width);
  }

  &__label {
    font-size: .875rem;

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      font-size: 1.125rem;
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      font-size: 1.375rem;
    }
  }

  &--active {
    pointer-events: all;

    .sticky-navigation-item__line {
      margin-right: .5rem;
      width: 1.5rem;
      border-top: 1px solid $color-secondary;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        width: 1rem;
      }

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        width: 1.5rem;
      }
    }
  }

  &:not(.sticky-navigation-item--active) {
    .sticky-navigation-item__line {
      width: 0;
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    display: block;
    margin-right: 0;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sticky-navigation-element {
  @include transition(color);

  display: flex;
  align-items: center;

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    &:hover {
      color: $color-secondary-dark;

      svg path {
        stroke: $color-secondary-dark;
      }
    }
  }
}

// main block
.sticky-navigation-wrapper {
  @include transition(opacity);

  position: static;
  width: 100%;
  background: $color-grey-dark-1;
  z-index: 2;

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    display: grid;
    grid-template-columns: var(--container-grip-template-cols);
    grid-column-gap: var(--container-grid-gap);
    column-gap: var(--container-grid-gap);
    grid-auto-flow: dense;
    max-width: var(--container-max-width);
    margin-left: var(--container-grid-outer-gap);
    margin-right: var(--container-grid-outer-gap);
    padding: var(--container-grid-outer-padding);
    padding-bottom: 0;
    z-index: 1;
  }

  &--floating {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }

  &--hidden {
    opacity: 0;
  }

  ~ section:first-of-type {
    padding-top: var(--spacer-xs);

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      padding-top: 0;
    }
  }
}

.sticky-navigation {
  display: flex;
  align-items: center;
  min-height: 43px;
  padding-left: var(--container-grid-outer-gap);
  background: $color-grey-dark-1;
  white-space: nowrap;
  overflow-x: scroll;
  scrollbar-width: none;
  width: 100%;
  z-index: 3;
  transition: 1s all ease;

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    grid-column: 1 / span 12;
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    width: min-content;
    padding-left: 0;
    background: transparent;
    white-space: initial;
    overflow-x: initial;
  }

  &.stick-to-bottom {
    top: auto;
    bottom: 0;
  }

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }

  &--pole {
    margin-top: -100px;
    position: absolute;
  }

  &--pull-up {
    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      position: relative;
      z-index: 2;
    }
  }
}
