// helper blocks
.boutique-teaser {
  .row-03 {
    margin-bottom: var(--spacer-l);
  }

  .row-04 {
    grid-template-areas:
      ". a1 a1 a1 a1 ."
      "a2 a2 a2 a2 a2 a2"
      ". . a3 a3 a3 a3";
    margin-bottom: var(--spacer-l);

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-areas:
        "a1 a1 a1 a2 a2 a2 a2 a2 a2 a2 a2 ."
        ". . . a3 a3 a3 . . . . . . ";
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      grid-template-areas: "a1 a1 a1 . a2 a2 a2 a2 a2 a3 a3 a3";
    }

    .col-01 {
      grid-area: a1;
      margin-bottom: var(--spacer-m);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      grid-area: a2;
      margin-bottom: var(--spacer-m);

      @media screen and (min-width: map-get($breakpoints, "large")) {
        margin-bottom: 0;
      }
    }

    .col-03 {
      grid-area: a3;
    }
  }

  .row-05 {
    grid-template-areas:
      "b1 b1 b1 b1 b1 b1"
      ". b2 b2 b2 b2 b2";

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-areas:
        "b1 b1 b1 b1 b1 b1 b1 b1 b1 b1 b1 b1"
        ". . . b2 b2 b2 b2 b2 b2 b2 b2 b2";
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      grid-template-areas: "b1 b1 b1 . b2 b2 b2 b2 b2 b2 b2 b2";
    }

    .col-01 {
      grid-area: b1;

      .heading {
        margin-bottom: var(--spacer-l);

        @media screen and (min-width: map-get($breakpoints, "large")) {
          margin-bottom: 0;
        }
      }
    }

    .col-02 {
      grid-area: b2;
    }
  }
}
