.simple-string-list {
  margin-bottom: var(--spacer-l);

  &__list {
    list-style: none;
  }

  &__list-item {
    display: flex;
    align-items: center;
  }

  &__list-item-line {
    position: relative;
    top: .8rem;
    align-self: flex-start;
    width: 1rem;
    margin-right: 1rem;
    border-top: 1px solid $color-black;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      top: 1rem;
      width: 2rem;
      margin-right: 1.5rem;
      border-width: 2px;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      top: 1.2rem;
    }
  }
}
