.news-hero {
  .main-hero__row-title {
    .col-01 {
      margin-bottom: 0;
    }

    .col-02 {
      display: none;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      .col-02 {
        display: block;
      }
    }
  }
}
