// main block
.card-outline-text {
  position: relative;
  background: $color-black;
  border-radius: $border-radius-12;
  aspect-ratio: 1/1;

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 1.5rem;
    text-decoration: none;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      padding: 2.5rem;
    }
  }

  &__number-wrapper {
    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__number {
    position: static;
    font-size: 8rem;
    line-height: 7rem;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      font-size: 13rem;
      line-height: 11rem;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      font-size: 8rem;
      line-height: 7rem;
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      font-size: 18rem;
      line-height: 15rem;
    }
  }

  &__content-wrapper {
    display: flex;
    gap: .5rem;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__text {
    @include line-clamp(2);

    margin-bottom: 0;
    font-weight: 700;
    color: $color-white;

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      @include line-clamp(3);
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      @include line-clamp(4);
    }
  }

  &__action {
    align-self: flex-end;
    flex: 0 0 auto;
    margin-left: .5rem;

    svg {
      @include transition(transform);

      width: 1.5rem;
      height: 1.5rem;

      path {
        stroke: $color-white;
      }

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    margin-bottom: 0;

    &:hover {
      .card-outline-text__action {
        svg {
          transform: translateX(.625rem);
        }
      }
    }
  }
}
