:root {
  --navigation-menu-col-animation:
    opacity 180ms ease-in-out,
    transform 350ms cubic-bezier(.39, .575, .565, 1);
}

// helper blocks
.navigation-menu-row {
  +.navigation-menu-row {
    margin-top: 3rem;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      margin-top: 6rem;
    }
  }
}

.navigation-menu-col {
  flex: 1;
  opacity: 0;
  transform: translateY(-50px);

  &__heading {
    display: inline-block;
    margin-bottom: 1.5rem;
    color: $color-black;
    text-decoration: none;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      margin-bottom: 2.5rem;

      &:hover {
        .navigation-menu-col {
          &__icon {
            right: -30px;
          }
        }
      }
    }
  }

  &__icon {
    @include transition(right);

    position: relative;
    right: 0;
    bottom: -.5rem;
    display: inline-flex;

    @media screen and (min-width: map-get($breakpoints, "large")) {
      bottom: -.3;
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      bottom: -1rem;
      width: 5rem;
      height: 5rem;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }
}

.navigation-menu-close-col {
  opacity: 0;
  transform: translateY(-50px);
}

// main block
.navigation-menu {
  position: fixed;
  height: 100vh;
  width: 100%;
  padding: 100px 0;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  overflow: hidden;
  transition: opacity 250ms ease-in-out;

  .row-01 {
    .col-01 {
      @include grid-column(1, -1, 1, 7);

      margin-bottom: 3rem;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
        padding: 0 3rem;
      }
    }

    .col-02 {
      @include grid-column(1, -1, 7, -1);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        padding: 0 3rem;
      }
    }
  }

  .row-02 {
    .col-01 {
      @include grid-column(1, -1);
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      padding: 0 3rem;
    }
  }

  &--is-open {
    pointer-events: all;
    opacity: 1;
    overflow: auto;

    /* stylelint-disable-next-line selector-class-pattern */
    .navigation-menu-row__col {
      opacity: 1;
      transform: translateY(0);
      transition: var(--navigation-menu-col-animation);

      &:nth-child(2) {
        transition-delay: .06s;
      }
    }

    .navigation-menu-close-col {
      opacity: 1;
      transform: translateY(0);
      transition: var(--navigation-menu-col-animation);
      transition-delay: .1s;
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    padding: 11.25rem 0 6.25rem;
  }
}
