// main block
.case-studies-teaser {
  .row-01 {
    margin-bottom: var(--spacer-l);
  }

  .row-02 {
    .col {
      grid-column: auto / span 6;

      .card-image-text {
        width: 70%;
        margin: 0 auto;
      }

      &:not(:first-child) {
        margin-top: var(--spacer-l);
      }

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        &:nth-child(4) {
          margin-top: calc(#{var(--spacer-l)} * 2);
        }

        .card-image-text {
          width: 100%;
        }
      }

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        .card-image-text {
          width: 82%;
        }
      }
    }
  }

  .row-04 {
    row-gap: var(--spacer-l);

    .col {
      grid-column: auto/span 6;
      width: 70%;
      margin: 0 auto;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        width: unset;
      }

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        grid-column: auto/span 4;
      }
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      row-gap: 0;
    }
  }
}
