// helper blocks
.case-strategy-slider {
  background: $color-black;
  padding: var(--spacer-l) 0 var(--spacer-xs);

  /* stylelint-disable-next-line selector-class-pattern */
  .row-swiper__slide {
    grid-template-areas:
      "b b b b b b"
      "a a a a a a ";

    .col-01 {
      grid-area: a;

      // Disabled for go live with just one slide
      // padding-bottom: 6rem;

      // @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      //   padding-bottom: 12rem;
      // }
    }

    .col-02 {
      grid-area: b;
      margin-top: 0;
      margin-bottom: 1.5rem;

      img {
        width: 4.5rem;
        border-radius: 50%;

        @media screen and (min-width: map-get($breakpoints, "medium-min")) {
          margin-left: auto;
        }

        @media screen and (min-width: map-get($breakpoints, "medium-max")) {
          width: 8.75rem;
        }
      }

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-template-areas: "b b b a a a a a a a a a";
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      grid-template-areas: "b b b b a a a a a a a a";
    }
  }
}

// main block
.case-study-creation {
  background: $color-grey-dark-1;
}
