// helper blocks
.contact-box-operator-row {
  .col-01 {
    @include grid-column(1, -1);

    .sub-row {
      grid-template-areas:
        "image image name name name ."
        "image image contacts contacts contacts .";

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        grid-template-areas: ". image image image name name name name contacts contacts contacts contacts contacts";
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        grid-template-areas: ". . image image name name name contacts contacts contacts contacts contacts contacts";
      }

      .sub-col-01 {
        grid-area: image;
      }

      .sub-col-02 {
        grid-area: name;
      }

      .sub-col-03 {
        grid-area: contacts;
      }
    }
  }

  + .contact-box-operator-row {
    margin-top: var(--spacer-m);
  }

  .btn-chat {
    position: relative;
    top: -1rem;
  }

  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    + .contact-box-operator-row {
      margin-top: var(--spacer-l);
    }
  }
}

// main block
.contact-box {
  padding: var(--spacer-xl) 0;

  .row-01 {
    margin-bottom: var(--spacer-m);

    .col-01 {
      @include grid-column(1, -1, 4, -1, null, null, 5, -1);
    }
  }
}
