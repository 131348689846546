// helper blocks
.notification-aside {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__line {
    position: relative;
    bottom: -3px;
    width: 1px;
    height: 100%;
    background: $color-primary;
  }
}

.notification-content {
  &__label {
    display: block;
    margin-bottom: 10px;
    color: $color-primary;
  }
}

.notification-content-body {
  &__text {
    color: $color-white;
  }

  &__link {
    display: inline;
    margin-left: 5px;
  }
}

// main block
.notification {
  display: flex;
  gap: 26px;
  width: 580px;
  padding: 32px 40px 35px 34px;
  background: $color-black;
  border-radius: $border-radius-8;

  &__content {
    padding-bottom: 5px;
  }
}

.notification-wrapper {
  display: none;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    position: fixed;
    top: calc(var(--header-height) + 1rem);
    right: 0;
    left: 100%;
    display: grid;
    opacity: 0;
    z-index: 1;
    transition: opacity 280ms cubic-bezier(.42, 0, .58, 1), left 280ms cubic-bezier(.42, 0, .58, 1);

    &--is-visible {
      left: 0;
      opacity: 1;
    }
  }
}
