// helper blocks
.case-study-strategy-slider {
  padding: var(--spacer-l) 0 var(--spacer-xs);
  background: $color-black;
}

// main block
.case-study-strategy {
  background: $color-grey-dark-1;
}
