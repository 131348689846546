// main block
.modal-teaser {
  position: fixed;
  display: none;
  width: 45rem;
  height: 59rem;
  z-index: 1;

  &--is-open {
    display: block;
  }

  &__body {
    position: relative;
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    height: 100%;
    padding: 2.5rem;
    background: $color-black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 1.875rem;

    &--wide {
      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        width: 60rem;
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        width: 80rem;
      }
    }
  }

  &__text {
    margin: auto 0 0;
  }

  &__btn {
    @include transition(transform);

    svg {
      path {
        stroke: $color-white;
      }
    }

    &:hover {
      transform: translateX(.5rem);
    }
  }
}
