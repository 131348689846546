/* stylelint-disable selector-class-pattern */
.swiper {
  --swiper-theme-color: rgb(104 222 222 / 100%);
  --swiper-pagination-bullet-inactive-color: rgb(211 211 211 / 100%);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 1rem;
  --swiper-pagination-bullet-size: .5rem;
  --swiper-navigation-size: 3rem;

  width: 100%;
  height: 100%;
  text-rendering: initial;

  &-wrapper {
    margin-bottom: 2.5rem;
  }

  &-slide {
    height: auto;
  }

  &-pagination {
    position: static;

    @media screen and (min-width: map-get($breakpoints, "large")) {
      padding: .625rem 0;
      text-align: left;
    }
  }

  &-button-prev,
  &-button-next {
    top: initial;
    bottom: 0;
    width: var(--swiper-navigation-size);
    width: var(--swiper-navigation-size);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 1;

    &::after {
      content: "";
    }
  }

  &-button-prev {
    left: 0;
    background-image: url("/src/assets/icons/swiper-arrow-prev.png");

    @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
      grid-column-start: 5;
      left: -100px;
    }
  }

  &-button-next {
    right: 0;
    background-image: url("/src/assets/icons/swiper-arrow-next.png");

    @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
      grid-column-start: -1;
      right: -100px;
    }
  }

  &--dark {
    --swiper-pagination-bullet-inactive-color: rgb(255 255 255 / 100%);
    --swiper-pagination-bullet-inactive-opacity: .3;

    background: $color-black;

    .swiper {
      &-button-prev,
      &-button-next {
        opacity: .3;
      }

      &-button-prev {
        background-image: url("/src/assets/svg/arrow-left.svg");
      }

      &-button-next {
        background-image: url("/src/assets/svg/arrow-right.svg");
      }
    }
  }

  &--disabled {
    pointer-events: none;

    .swiper-wrapper {
      cursor: auto !important;
      transform: translate3d(0, 0, 0) !important;
    }

    .row-swiper__pagination,
    .row-swiper__navigation {
      display: none;
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    --swiper-pagination-bullet-size: .75rem;
  }

  @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
    --swiper-navigation-size: 6rem;
  }
}

.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  margin: 0 auto .8rem;
  transform: translateX(0%);

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: unset;
  }
}

.row-swiper {
  &__slide {
    .col-01 {
      @include grid-column(1, -1, null, null, null, null, 5, -1);
    }

    .col-02 {
      @include grid-column(1, -1, null, null, null, null, 1, 4);

      margin-top: var(--spacer-xs);

      @media screen and (min-width: map-get($breakpoints, "large")) {
        margin-top: unset;
      }
    }
  }

  &__pagination {
    .col-01 {
      @include grid-column(1, -1, null, null, null, null, 5, -1);

      @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
        grid-column-start: 8;
      }
    }
  }

  &__navigation {
    @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
      bottom: 375px;
    }

    .col-01 {
      @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
        display: grid;
      }
    }
  }
}
