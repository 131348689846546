section {
  &.text-hero.sitemap {

  }
  &.main-teaser.sitemap{
    .col {
      grid-column-start: 1;
      grid-column-end: -1;

      @media screen and (min-width: map-get($breakpoints, "small")) {
        grid-column-start: 2;
        grid-column-end: -1;
      }
      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        grid-column-start: 4;
        grid-column-end: -1;
      }

      &.col-01 {
        margin-bottom: 75px;

        @media screen and (min-width: map-get($breakpoints, "medium-max")) {
          grid-column-start: 3;
          grid-column-end: 8;
          margin-bottom: 0;
        }
        @media screen and (min-width: map-get($breakpoints, "extra-large")) {
          grid-column-start: 4;
          grid-column-end: 8;
        }
      }
      &.col-02 {
        @media screen and (min-width: map-get($breakpoints, "medium-max")) {
          grid-column-start: 8;
          grid-column-end: 13;
        }
        @media screen and (min-width: map-get($breakpoints, "extra-large")) {
          grid-column-start: 8;
          grid-column-end: 12;
        }
      }
    }
  }
}

.url-wrapper {
  display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  position: relative;
  width: 100%;

  &:before {
    content: '';
    background-color: rgb(0, 0, 0);
    height: 100%;
    position: absolute;
    left: 15px;
    top: 0;
    width: 1px;
    z-index: -1;
  }

  &__item {
    flex: 0 0 auto;
    margin-bottom: 15px;
    position: relative;
    width: 100%;

    &:before {
      content: '';
      background-color: rgb(0, 0, 0);
      height: 1px;
      position: absolute;
      left: 15px;
      top: calc(50% - .5px);
      width: 15px;
      z-index: -1;
    }

    &.first-depth.dark-bg {
      margin-bottom: 30px;

      a {
        background-color: rgb(0, 0, 0);
        color: rgb(245, 223, 77);

        &:before {

        }
      }
    }
    &.second-depth.yellow-bg {
      padding-left: 30px;

      a {
        background-color: rgb(245, 223, 77);

        &:before {

        }
      }
    }
    &.third-depth.grey-bg {
      padding-left: 60px;

      &:before {
        left: 45px;
      }

      a {
        background-color: rgb(245,245,245);

        &:before {

        }
      }

      &:last-of-type:after {
        content: '';
        background-color: rgb(255, 255, 255);
        height: 100%;
        position: absolute;
          left: 45px;
          top: 0;
        transform: translateY(50%);
        width: 1px;
        z-index: 0;
      }
    }

    > a {
      border: 1px solid rgb(0, 0, 0);
      border-radius: 10px;
      color: rgb(0, 0, 0);
      display: block;
      font-weight: 600;
      line-height: normal;
      padding: 20px;
      position: relative;
      text-decoration: none;
    }
  }

  &.news,
  &.career__categories {
    &:after {
      content: '';
      background-color: rgb(0, 0, 0);
      height: 100%;
      position: absolute;
        left: 45px;
        top: 15px;
      width: 1px;
      z-index: -1;
    }
  }

  &.agb .url-wrapper__item:after {
    content: '';
    background-color: rgb(255, 255, 255);
    height: 100%;
    position: absolute;
      left: 15px;
      top: 0;
    transform: translateY(50%);
    width: 1px;
    z-index: 0;
  }

  &.career__categories.consultation:before {
    background-color: rgb(255, 255, 255);
    left: 15px;
    top: 48px;
    width: 1px;
    z-index: 0;
  }
}

.padding-top-15 {
  padding-top: 15px;
}

html[lang="en"] .sitemap .url-wrapper.career:before {
  content: none;
}
